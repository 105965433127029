import React, { FC, useEffect } from 'react'
import { useStore } from 'outstated'
import { parse } from 'querystring'

import { AuthStore } from '../services'

const AuthenticatePage: FC = () => {
  const auth = useStore(AuthStore)

  useEffect(() => {
    const { token, email } = parse(window.location.search.substr(1))

    if (typeof token === 'string' && typeof email === 'string') {
      auth.handleLoginWithPasswordlessToken({ token, email }).then(() => {
        window.close()
      })
    }
  }, [])

  return null
}

export default AuthenticatePage
